import React from 'react';

import './App.css';
import { ChakraProvider, extendTheme} from "@chakra-ui/react"
import { Box, Input, Textarea, Button, Stack, InputGroup, InputLeftElement, InputRightElement } from "@chakra-ui/react"
import { PhoneIcon, CheckIcon } from '@chakra-ui/icons'

import { FaUserAlt, FaBuilding, FaQuestionCircle } from 'react-icons/fa';
import { IoIosMail, IoMdMail, IoMdDocument } from 'react-icons/io'

import TextLoop from "react-text-loop";

import { Formik, Form, Field } from 'formik';

import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
} from "@chakra-ui/react"

import ReCAPTCHA from "react-google-recaptcha";

import axios from 'axios';

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react"

const theme = extendTheme({
  colors: {
    teal: "#00A0B0",
    blue: '#00589B',
    red: "#CF5C78",
    white: '#FFFFFF'
  }
})


const recaptchaBusinessRef = React.createRef();
const recaptchaRefTeam = React.createRef();

class App extends React.Component {
  formTimeout;
  constructor(props){
    super(props);
    console.log('App props', props);
    var context = {};
    if(props.context){
      context = props.context;
    } else if(typeof window !== 'undefined' && window.reactContext) {
      context = window.reactContext;
    }
    
    this.state = {
      businessFormLoading: false,
      teamFormLoading: false,
      formLoading: false,
      isFormModalOpen: false,
      isErrorModalOpen: false,
      modalErrorMessage: '',
      uploadFileName: null,
      uploadFile: null,
      bgSource: this.getBgSource(context),
      recaptchaBusinessToken: null,
      recaptchaTeamToken: null, 
    };
  }

  componentDidMount(){
  }

  getBgSource(context){
    return (context.query.bg ) ? "url('"+context.query.bg+"') !important" : 'url(/giphy.gif)'
  }

  async submitBusinessForm(values, actions){
    if(!this.state.formLoading){
      console.log('submitBusinessForm values', values)
      if(this.formTimeout) clearTimeout(this.formTimeout);
      this.setState(state => state.formLoading = true)
      this.setState(state => state.businessFormLoading = true)
      
      this.formTimeout = setTimeout(() => { this.sendBusinessForm(values) }, 1000);
    }
  }
  async sendBusinessForm(values){
    
    let payload = {
      'g-recaptcha-response': this.state.recaptchaBusinessToken,
      name: values.name,
      email: values.email,
      company: values.company,
      message: values.message
    };

    await axios.post('/contact', payload);

    this.setState(state => state.businessFormLoading = false)
    this.setState(state => state.formLoading = false)
    this.setState(state => state.isFormModalOpen = true)
  }
  async submitTeamForm(values, actions){
    if(!this.state.formLoading){
      console.log('submitTeamForm values', values)
      if(this.formTimeout) clearTimeout(this.formTimeout);
      this.setState(state => state.formLoading = true)
      this.setState(state => state.teamFormLoading = true)
      
      this.formTimeout = setTimeout(() => { this.sendTeamForm(values) }, 1000);
    }
  }
  async sendTeamForm(values){
   
    const data = new FormData() 
    data.append('name', values.name)
    data.append('email', values.email)
    data.append('message', values.message)
    data.append('file', this.state.uploadFile)

    try {
      let response = await axios.post(`/contact/join?g-recaptcha-response=${this.state.recaptchaTeamToken}`, data);
      console.log('POST response', response)
      console.log('POST response.data', response.data)
      console.log('POST response.data.error', response.data.error)

      this.setState(state => state.teamFormLoading = false)
      this.setState(state => state.formLoading = false)

      if(response.data.error){

        var errorMessages = {
          LIMIT_PART_COUNT: 'Too many parts',
          LIMIT_FILE_SIZE: 'File too large',
          LIMIT_FILE_COUNT: 'Too many files',
          LIMIT_FIELD_KEY: 'Field name too long',
          LIMIT_FIELD_VALUE: 'Field value too long',
          LIMIT_FIELD_COUNT: 'Too many fields',
          LIMIT_UNEXPECTED_FILE: 'Unexpected field'
        }

        if(response.data.error == "INVALID_UPLOAD_FILEFORMAT"){
          this.openErrorModal('Supported file types are: .pdf, .txt, .doc and .xps.');
        } else if (response.data.error == "LIMIT_FILE_SIZE"){ 
            this.openErrorModal('The uploaded file must be smaller than 50 MB.');
        } else if(errorMessages[response.data.error]){
            this.openErrorModal(errorMessages[response.data.error]);
        } else {
          this.openErrorModal('Something went wrong');
        }
      } else {
        this.setState(state => state.isFormModalOpen = true)
      }

    } catch(err) {
      console.log('POST ERR', err)
      this.setState(state => state.teamFormLoading = false)
      this.setState(state => state.formLoading = false)
      this.openErrorModal('Something went wrong');
      // INVALID_UPLOAD_FILEFORMAT
    }

    
  }

  changeHandler(e, property){
    this.setState(state => state[property] = e.target.value)
  }

  closeFormModal(){
    this.setState(state => state.isFormModalOpen = false)
  }

  closeErrorModal(){
    this.setState(state => state.isErrorModalOpen = false)
  }

  openErrorModal(errorMessage){
    this.setState(state => state.modalErrorMessage = errorMessage)
    this.setState(state => state.isErrorModalOpen = true)
  }

  onFileChangeHandler(event) {
    let file = event.target.files[0];
    console.log('@onFileChangeHandler', file);
    this.setState(state => state.uploadFileName = file.name)
    this.setState(state => state.uploadFile = file)
  }
  render() {
    

    return (
      <ChakraProvider theme={theme}>

        <div id="page">
          <div id="background" style={ {backgroundImage: this.state.bgSource } }></div>
          <div id="content">
            <div id="header">
              <a href="/" id="logo">{/**/}</a>
            </div>
            <div id="title">
              <div className="wrap">
                <h1>Expanding<br></br> the <span className="textloop"><TextLoop>
                  <span>digital</span>
                  <span>virtual</span>
                  <span>gaming</span>
                </TextLoop></span> world.</h1>
              </div>
            </div>
            <div id="rainbow-head" className="rainbow-head">
              <div className="wrap">
                <div id="t1" className="rainbow-head-title">Work with us</div>
                <div id="t2" className="rainbow-head-title">Join our team</div>
              </div>
            </div>
            <div id="forms">
              <div className="wrap">
                
                <div id="form1" className="form">
                    <div className="form-in">
                      <div className="rainbow-head mobile">
                        <div className="rainbow-head-title">Work with us</div>
                      </div>
                      <div className="form-description">
                        If you are interested in working with us on an awesome project, fill in the form and we'll contact you right away.
                      </div>
                      <div className="form-fields">
                          <Stack spacing={4}>
                            <Formik 
                              onSubmit={async (values, actions) => {

                                const token = await recaptchaBusinessRef.current.executeAsync();
                                if(token){
                                  this.setState(state => state.recaptchaBusinessToken = token, async () => { 
                                      await this.submitBusinessForm(values, actions)
                                      actions.setSubmitting(false)
                                      actions.resetForm();
                                  })
                                } else {
                                  this.openErrorModal("Invalid Captcha")
                                }
                                
                              }}
                              initialValues={
                                {
                                  name: '',
                                  email: '',
                                  company: '',
                                  message: ''
                                }
                              }
                            >{(props) => { 
                              return <Form>

                                <ReCAPTCHA
                                  ref={recaptchaBusinessRef}
                                  size="invisible"
                                  sitekey="6LcIiIQaAAAAAJSbrWCXQUX2dmz3UFeioNcEaun5"
                                  onChange={this.onChange}
                                />    
                                <Field name="name">
                                  {({field, form}) => (
                                    <FormControl isInvalid={form.errors.name && form.touched.name}>
                                      <InputGroup>
                                        <InputLeftElement
                                          pointerEvents="none"
                                          children={<Box color="blue" fontSize="16px"><FaUserAlt/></Box>}
                                        />
                                        <Input isRequired isDisabled={this.state.formLoading} {...field} id="name" bg="white" color="blue" border="none" _hover={ {bg: 'white'}} borderRadius="3xl" _focus={ {bg: 'whiteAlpha.800'}} type="text" placeholder="your name" />
                                      </InputGroup>
                                    </FormControl>
                                  )}
                                </Field>

                                <Field name="email">
                                  {({field, form}) => (
                                    <FormControl isInvalid={form.errors.email && form.touched.email}>
                                      <InputGroup>
                                        <InputLeftElement
                                          pointerEvents="none"
                                          children={<Box color="blue" fontSize="16px"><IoMdMail/></Box>}
                                        />
                                        <Input isRequired isDisabled={this.state.formLoading} {...field} id="email" bg="white" color="blue" border="none" _hover={ {bg: 'white'}} borderRadius="3xl" _focus={ {bg: 'whiteAlpha.800'}} type="text" placeholder="your email" />
                                      </InputGroup>
                                    </FormControl>
                                  )}
                                </Field>

                                <Field name="company">
                                  {({field, form}) => (
                                    <FormControl isInvalid={form.errors.company && form.touched.company}>
                                      <InputGroup>
                                        <InputLeftElement
                                          pointerEvents="none"
                                          children={<Box color="blue" fontSize="16px"><FaBuilding/></Box>}
                                        />
                                        <Input isRequired isDisabled={this.state.formLoading} {...field} id="company" bg="white" color="blue" border="none" _hover={ {bg: 'white'}} borderRadius="3xl" _focus={ {bg: 'whiteAlpha.800'}} type="text" placeholder="your company" />
                                      </InputGroup>
                                    </FormControl>
                                  )}
                                </Field>

                                <Field name="message">
                                  {({field, form}) => (
                                    <FormControl isInvalid={form.errors.message && form.touched.message}>
                                      <InputGroup>
                                        <InputLeftElement
                                          pointerEvents="none"
                                          children={<Box color="blue" fontSize="16px"><FaQuestionCircle/></Box>}
                                        />
                                        <Textarea isRequired isDisabled={this.state.formLoading} {...field} style={{paddingLeft: '40px'}} id="message" bg="white" color="blue" border="none" _hover={ {bg: 'white'}} borderRadius="3xl" _focus={ {bg: 'whiteAlpha.800'}} type="text" placeholder="project/topic"></Textarea>
                                      </InputGroup>
                                    </FormControl>
                                  )}
                                </Field>
                                <Button isLoading={this.state.businessFormLoading} type="submit" bg="blue" style={{textTransform:'uppercase', width: '100%', margin: '0 0 0 0'}} borderRadius="3xl" _hover={{bg: 'blue'}} _focus={{bg: 'blue'}}>Send to Secret Labs</Button>
                              </Form>

                            }}</Formik>

                              {/** Your Name 
                              <InputGroup>
                                <InputLeftElement
                                  pointerEvents="none"
                                  children={<Box color="blue" fontSize="16px"><FaUserAlt/></Box>}
                                />
                                <Input isDisabled={this.state.formLoading} onChange={ (e) => { this.changeHandler(e, 'businessName') }} value={this.state.businessName} bg="white" color="blue" border="none" _hover={ {bg: 'white'}} borderRadius="3xl" _focus={ {bg: 'whiteAlpha.800'}} type="text" placeholder="your name" />
                              </InputGroup>
*/}
                              {/** Your Email 
                              <InputGroup>
                                <InputLeftElement
                                  pointerEvents="none"
                                  color="blue"
                                  fontSize="1.2em"
                                  children={<Box color="blue" fontSize="16px"><IoMdMail/></Box>}
                                />
                                <Input isDisabled={this.state.formLoading} onChange={ (e) => { this.changeHandler(e, 'businessEmail') }} value={this.state.businessEmail} bg="white" color="blue" border="none" _hover={ {bg: 'white'}} borderRadius="3xl" _focus={ {bg: 'whiteAlpha.800'}} type="text" placeholder="your email" />
                              </InputGroup>
*/}
                              {/** Your Company 
                              <InputGroup>
                                <InputLeftElement
                                  pointerEvents="none"
                                  color="blue"
                                  fontSize="1.2em"
                                  children={<Box color="blue" fontSize="16px"><FaBuilding/></Box>}
                                />
                                <Input isDisabled={this.state.formLoading} onChange={ (e) => { this.changeHandler(e, 'businessCompany') }} value={this.state.businessCompany} bg="white" color="blue" border="none" _hover={ {bg: 'white'}} borderRadius="3xl" _focus={ {bg: 'whiteAlpha.800'}} type="text" placeholder="your company" />
                              </InputGroup>
*/}
                              {/** Project/Topic 
                              <InputGroup>
                                <InputLeftElement
                                  pointerEvents="none"
                                  color="blue"
                                  fontSize="1.2em"
                                  children={<Box color="blue" fontSize="16px"><FaQuestionCircle/></Box>}
                                />
                                <Textarea isDisabled={this.state.formLoading}  onChange={ (e) => { this.changeHandler(e, 'businessMessage') }} value={this.state.businessMessage} style={{paddingLeft: '40px'}} bg="white" color="blue" border="none" _hover={ {bg: 'white'}} borderRadius="3xl" _focus={ {bg: 'whiteAlpha.800'}} type="text" placeholder="project/topic" />
                              </InputGroup>
*/}
                              
                            </Stack>
                      </div>
                    </div>
                  </div>

                <div id="form2" className="form">
                    <div className="form-in">
                    <div className="rainbow-head mobile">
                        <div className="rainbow-head-title">Join the team</div>
                      </div>
                      <div className="form-description">
                        If you're excited to join our secret labs and work with talented professionals from all around the world, fill in the form below.
                      </div>
                      <div className="form-fields">
                            <Stack spacing={4}>
                            <Formik 
                              onSubmit={async (values, actions) => {
                                const token = await recaptchaRefTeam.current.executeAsync();
                                if(token){
                                  this.setState(state => state.recaptchaTeamToken = token, async () => { 
                                      await this.submitTeamForm(values, actions)
                                      actions.setSubmitting(false)
                                      actions.resetForm();
                                  })
                                } else {
                                  this.openErrorModal("Invalid Captcha")
                                }

                                
                              }}
                              initialValues={
                                {
                                  name: '',
                                  email: '',
                                  resume: '',
                                  message: ''
                                }
                              }
                            >{(props) => { 
                              return <Form>

                                <ReCAPTCHA
                                  ref={recaptchaRefTeam}
                                  size="invisible"
                                  sitekey="6LcIiIQaAAAAAJSbrWCXQUX2dmz3UFeioNcEaun5"
                                  onChange={this.onChange}
                                />   

                                <Field name="name">
                                  {({field, form}) => (
                                    <FormControl isInvalid={form.errors.name && form.touched.name}>
                                      <InputGroup>
                                        <InputLeftElement
                                          pointerEvents="none"
                                          children={<Box color="teal" fontSize="16px"><FaUserAlt/></Box>}
                                        />
                                        <Input isRequired isDisabled={this.state.formLoading} {...field} id="name" bg="white" color="teal" border="none" _hover={ {bg: 'white'}} borderRadius="3xl" _focus={ {bg: 'whiteAlpha.800'}} type="text" placeholder="your name" />
                                      </InputGroup>
                                    </FormControl>
                                  )}
                                </Field>

                                <Field name="email">
                                  {({field, form}) => (
                                    <FormControl isInvalid={form.errors.email && form.touched.email}>
                                      <InputGroup>
                                        <InputLeftElement
                                          pointerEvents="none"
                                          children={<Box color="teal" fontSize="16px"><IoMdMail/></Box>}
                                        />
                                        <Input isRequired isDisabled={this.state.formLoading} {...field} id="email" bg="white" color="teal" border="none" _hover={ {bg: 'white'}} borderRadius="3xl" _focus={ {bg: 'whiteAlpha.800'}} type="text" placeholder="your email" />
                                      </InputGroup>
                                    </FormControl>
                                  )}
                                </Field>

                                <Field name="resume">
                                  {({field, form}) => (
                                    <FormControl isInvalid={form.errors.resume && form.touched.resume}>
                                      <InputGroup>
                                        <InputLeftElement
                                          pointerEvents="none"
                                          children={<Box color="teal" fontSize="16px"><IoMdDocument/></Box>}
                                        />
                                        <div className="input-mask">
                                          <input className="input-file" onChange={(event) => { this.onFileChangeHandler(event) }} disabled={this.state.formLoading} id="resume" type="file" placeholder="your resume" />
                                          <Box className="input input-imitator" bg="white" color="teal" border="none" _hover={ {bg: 'white'}} borderRadius="3xl" _focus={ {bg: 'whiteAlpha.800'}} ><Box className="input-imitator-label">{this.state.uploadFileName ? this.state.uploadFileName : 'upload resume'}</Box></Box>
                                        </div>
                                      </InputGroup>
                                    </FormControl>
                                  )}
                                </Field>

                                <Field name="message">
                                  {({field, form}) => (
                                    <FormControl isInvalid={form.errors.message && form.touched.message}>
                                      <InputGroup>
                                        <InputLeftElement
                                          pointerEvents="none"
                                          children={<Box color="teal" fontSize="16px"><FaQuestionCircle/></Box>}
                                        />
                                        <Textarea maxLength={512} isRequired isDisabled={this.state.formLoading} {...field} style={{paddingLeft: '40px'}} id="message" bg="white" color="teal" border="none" _hover={ {bg: 'white'}} borderRadius="3xl" _focus={ {bg: 'whiteAlpha.800'}} type="text" placeholder="what makes you a perfect fit"></Textarea>
                                      </InputGroup>
                                    </FormControl>
                                  )}
                                </Field>
                                <Button isLoading={this.state.teamFormLoading} type="submit" bg="teal" style={{textTransform:'uppercase', width: '100%', margin: '0 0 0 0'}} borderRadius="3xl" _hover={{bg: 'teal'}} _focus={{bg: 'teal'}}>Join the Secret Labs</Button>
                              </Form>

                            }}</Formik>
                              {/** Your Name 
                              <FormControl id="businessName" isRequired>
                                <InputGroup>
                                  <InputLeftElement
                                    pointerEvents="none"
                                    children={<Box color="teal" fontSize="16px"><FaUserAlt/></Box>}
                                  />
                                  <Input bg="white" color="teal" border="none" _hover={ {bg: 'white'}} borderRadius="3xl" _focus={ {bg: 'whiteAlpha.800'}} type="text" placeholder="your name" />
                                </InputGroup>
                                <FormErrorMessage>Something</FormErrorMessage>
                              </FormControl>*/}

                              {/** Your Email 
                              <InputGroup>
                                <InputLeftElement
                                  pointerEvents="none"
                                  color="blue"
                                  fontSize="1.2em"
                                  children={<Box color="teal" fontSize="16px"><IoMdMail/></Box>}
                                />
                                <Input bg="white" color="teal" border="none" _hover={ {bg: 'white'}} borderRadius="3xl" _focus={ {bg: 'whiteAlpha.800'}} type="text" placeholder="your email" />
                              </InputGroup>*/}

                              {/** Your Resume 
                              <InputGroup>
                                <InputLeftElement
                                  pointerEvents="none"
                                  color="blue"
                                  fontSize="1.2em"
                                  children={<Box color="teal" fontSize="16px"><IoMdDocument/></Box>}
                                />
                                <Input bg="white" color="teal" border="none" _hover={ {bg: 'white'}} borderRadius="3xl" _focus={ {bg: 'whiteAlpha.800'}} type="text" placeholder="your resume" />
                              </InputGroup>*/}

                              {/** What makes you a perfect fit
                              <InputGroup>
                                <InputLeftElement
                                  pointerEvents="none"
                                  color="blue"
                                  fontSize="1.2em"
                                  children={<Box color="teal" fontSize="16px"><FaQuestionCircle/></Box>}
                                />
                                <Textarea style={{paddingLeft: '40px'}} bg="white" color="teal" border="none" _hover={ {bg: 'white'}} borderRadius="3xl" _focus={ {bg: 'whiteAlpha.800'}} type="text" placeholder="what makes you a perfect fit" />
                              </InputGroup>

                              <Button onClick={this.submitBusinessForm.bind(this)} bg="teal" style={{textTransform:'uppercase'}} borderRadius="3xl" _hover={{bg: 'teal'}} _focus={{bg: 'teal'}}>Join the Secret Labs</Button>
 */}
                            </Stack>
                      </div>
                    </div>
                  </div>
              </div>
            </div>

            <div id="footer">
              <div id="copyright">© 2021 Horizon Labs B.V.</div>
            </div>
          </div>
        </div>
      </ChakraProvider>
    );
  }
}

export default App;
